/*-- ========================================================

Template Name: eTrade || Multipurpose eCommerce HTML5 Template
Template URL: http://new.axilthemes.com/demo/template/etrade/
Description: Modern and Multipurpose eCommerce HTML5 Template
Version: 1.1
Author: Axilthemes
Author URL: https://www.axilthemes.com/
Date Created: September 01, 2022

======================================================== --*/

/*-- ========================================================
	
STYLESHEET INDEXING
|
|___ Default Styles
|	|
|	|___ Variables
|	|___ Reset Styels
|	|___ Typography
|	|___ Extend
|	|___ Animations
|	|___ Shortcode
|	|___ Common Style 
|	|___ Forms Styles 
|
|___Elements Styles
|	|___ About Us
|	|___ Back To Top
|	|___ Breadcrumb Styles
|	|___ Button Styles
|	|___ Categories
|	|___ Contact
|	|___ Countdown
|	|___ Error
|	|___ Newsletter
|	|___ Pagination
|	|___ Poster
|	|___ Price Slider
|	|___ Privacy Policy
|	|___ Section
|	|___ Service
|	|___ Slick Style
|	|___ Slider Style
|	|___ Social Share
|	|___ Team Style
|	|___ Testimonial Style
|	|___ Video Style
|
|___Blocks Styles
|	|___ Header Styles
|	|___ Shop Styles
|	|___ Blog Styles
|	|___ Footer Styles
|
|
|___ END STYLESHEET INDEXING

======================================================== --*/


/* ===============================
Default Styles
================================== */

@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/common';
@import 'default/forms';

/* ===============================
Elements Styles
================================== */

@import 'elements/about';
@import 'elements/back-to-top';
@import 'elements/breadcrumb';
@import 'elements/button';
@import 'elements/categories';
@import 'elements/contact';
@import 'elements/countdown';
@import 'elements/error';
@import 'elements/newsletter';
@import 'elements/pagination';
@import 'elements/poster';
@import 'elements/price-slider';
@import 'elements/privacy-policy';
@import 'elements/section';
@import 'elements/service';
@import 'elements/slick';
@import 'elements/slider';
@import 'elements/social';
@import 'elements/team';
@import 'elements/testimonial';
@import 'elements/video';
@import 'elements/splash';

/* ===============================
Header Styles
================================== */
@import 'header/header-top';
@import 'header/header';
@import 'header/nav';

/* ===============================
Shop Styles
================================== */

@import 'shop/header-cart';
@import 'shop/shop';
@import 'shop/product-details';
@import 'shop/checkout';
@import 'shop/my-account';

/* ===============================
Blog Styles
================================== */

@import 'blog/blog-list';
@import 'blog/blog-single';
@import 'blog/comment';
@import 'blog/sidebar';

/* ===============================
Footer Styles
================================== */

@import 'footer/footer';

/* ===============================
Spacing
================================== */
@import 'default/spacing';

/* ===============================
Dark Style
================================== */
@import 'dark/dark';